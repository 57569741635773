import React from 'react';

import { ScImageTextCardItem, ScImageTextCardItemTextWrapper } from './styled';

export interface IImageTextCardItem {
	title: string;
	text: string;
	image: string;
}

interface IImageTextCard {
	item: IImageTextCardItem;
	background?: string;
	color?: string;
	isLargeImage?: boolean;
	isSmallText?: boolean;
}

export const ImageTextCard = ({ item, background, color, isLargeImage, isSmallText }: IImageTextCard) => {
	return (
		<ScImageTextCardItem background={background} isLargeImage={isLargeImage}>
			<img src={item.image} alt={item.title} />
			<ScImageTextCardItemTextWrapper isLargeImage={isLargeImage} color={color} isSmallText={isSmallText}>
				<h6 dangerouslySetInnerHTML={{ __html: item.title }} />
				<p dangerouslySetInnerHTML={{ __html: item.text }} />
			</ScImageTextCardItemTextWrapper>
		</ScImageTextCardItem>
	);
};
